function loadScript(url){
  let script = document.createElement("script")
  script.type = "text/javascript";

  if (script.readyState){  //IE
    script.onreadystatechange = function(){
      if (script.readyState === "loaded" || script.readyState === "complete"){
        script.onreadystatechange = null;
      }
    };
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

function loadStyle(url){
  let link  = document.createElement('link');
  link.rel  = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  link.media = 'all';
  document.getElementsByTagName("head")[0].appendChild(link);
}

// const FW_DOMAIN = 'fleetwire.test'
// const FW_SCHEMA = '//'
// const FW_DOMAIN = 'fleetwire.io'
const FW_SCHEMA = 'https://';
const FW_DOMAIN = 'fleetwire.io';

try {
  let jsAssets = [];
  jsAssets.push(`${FW_SCHEMA}${FW_DOMAIN}/tenant/v2/fwtent.js?id=1694211414168`);

  let cssAssets = [];
  cssAssets.push(`${FW_SCHEMA}${FW_DOMAIN}/tenant/v2/fleetwire.css?id=1694211414168`);
  cssAssets.push(`${FW_SCHEMA}${FW_DOMAIN}/tenant/fleetwire-cart.css?id=1694211414168`);


  let options = window.fleetwireOptions || (window.fleetwireOptions = {});


  if (!window.fleetwireOptions['clientLocationJS']) {
    window.fleetwireOptions['clientLocationJS'] = jsAssets;
  }

  if (!window.fleetwireOptions['clientLocationCSS']) {
    window.fleetwireOptions['clientLocationCSS'] = cssAssets;
  }

  if (window.fleetwireOptions['company']) {
    window.fleetwireOptions['slug'] = window.fleetwireOptions['company'].toLowerCase().replace(/[^\w\s-_]+/g, '').replace(/[\s_]+/g, '-');
  }

  if (window.fleetwireOptions['company'] && !window.fleetwireOptions['apiURL']) {
    window.fleetwireOptions['apiURL'] = FW_SCHEMA + window.fleetwireOptions['slug'] + `.${FW_DOMAIN}`;
    // window.fleetwireOptions['apiURL'] = 'https://' + window.fleetwireOptions['slug'] + `.fleetwire.io`;
  }



  for (let i = 0; i < window.fleetwireOptions['clientLocationJS'].length; i++) {
    loadScript(window.fleetwireOptions['clientLocationJS'][i]);
  }

  for (let i = 0; i < window.fleetwireOptions['clientLocationCSS'].length; i++) {
    loadStyle(window.fleetwireOptions['clientLocationCSS'][i]);
  }



  // console.dir('FW_DEBUG ON');
  // console.table(options);
  //
  // let e;
  // (e = document.getElementById("fleetwire-cart")) || ((e = document.createElement("div")).setAttribute("id", "fleetwire-cart"));
  // document.getElementsByTagName("body")[0].appendChild(e);

} catch (e) {
  console.dir(e)
}
